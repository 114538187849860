// Import the functions you need from the SDKs you need

//import firebase from "firebase";
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
//import firebase from "firebase";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import "firebase/compat/messaging"
//import "firebase/compat/messaging"

const config = {
    apiKey: "AIzaSyBJPUSDJMqMx7_rq_9aEVha6gg6Go4tfNY",
    authDomain: "nexchess-c5fd1.firebaseapp.com",
    databaseURL: "https://nexchess-c5fd1-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nexchess-c5fd1",
    storageBucket: "nexchess-c5fd1.appspot.com",
    messagingSenderId: "568909639076",
    appId: "1:568909639076:web:f4b255c50c95379e962531",
    measurementId: "G-L6TY4PG1MT"
};


firebase.initializeApp(config);
// const db=firebase.database();
export const db = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore()
export const firebases = firebase;