import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';

import background from "./assets/background.jpg"
import back_background from "./assets/back_image.png"

import top_background from "./assets/top_nex.png"
import top_back from "./assets/top_back.png"
import right from "./assets/right_gif.gif"

import app_store from './assets/app_download.jpg'
import play_store from './assets/play_store.png'

import marine_1 from './assets/Marine_1.png'
import marine_2 from './assets/Marine_2.png'
import marine_3 from './assets/Marine_3.png'
import marine_4 from './assets/Marine_4.png'
import marine_5 from './assets/Marine_5.png'

import nexchess_big from "./assets/nexchess_big.png"
import leader_blank from "./assets/leader_blank.png"
import board_blank from "./assets/board_blank.png"
import FlatList from 'flatlist-react/lib';
import Leader_board from "./assets/leader_board.png"
import fire from "./assets/fire.gif"
import heli_right from "./assets/heli.png"
import jet_right from "./assets/jet.png"
import jet_left from "./assets/jet2.png"
import army from "./assets/stand_army_man.png"
import tank from "./assets/tank.png"
import { AiOutlineUser } from 'react-icons/ai'
import { BiSearch } from 'react-icons/bi'
import { db } from './config';
import YouTube, { YouTubeProps } from 'react-youtube';
import MediaQuery from 'react-responsive'

const userdb = db.ref('users')

export const leaderboard_color = '#6a232f'
export const color_text = '#e6b512'

function url(cd) {
  return `https://flagcdn.com/256x192/${cd}.png`
}

function App() {

  const [width, setWidth] = useState(window.innerWidth);

  const height = 563;

  console.log('Height ', height)

  const [rankings, setRankings] = useState(null);
  const [show_ranking, setShowRanking] = useState([])

  const inputref = useRef()

  useEffect(() => {
    if (rankings == null) {
      userdb.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          let new_arr = Object.values(snapshot.val());
          new_arr.sort((a, b) => {
            return b.Points - a.Points;
          })
          setRankings([...new_arr])
          setShowRanking([...new_arr])
        }
      })
    }
  })

  const [search, setSearch] = useState('')

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  function ranking(point) {
    if (point >= 280) return "GEN"
    else if (point >= 260) return "LT/GEN"
    else if (point >= 240) return "M/GEN"
    else if (point >= 220) return "BGD"
    else if (point >= 200) return "COL"
    else if (point >= 180) return "L/COL"
    else if (point >= 160) return "MAJ"
    else if (point >= 140) return "CPT"
    else if (point >= 120) return "LT"
    else if (point >= 100) return "2LT"
    else if (point >= 80) return "S/SGT"
    else if (point >= 60) return "SGT"
    else if (point >= 40) return "CPL"
    else if (point >= 20) return "L/CPL"
    else {
      return 'Marine'
    }
    // Lieutenant
  }

  const opts = {
    height: '300',
    width: '340',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const renderStandings = (item) => {
    return (
      <div style={{ padding: 4, cursor: 'pointer', display: 'flex', width: isMobile ? '99%' : '76%', flexDirection: 'column', justifyContent: 'center', marginBottom: 2, alignItems: 'center', height: 74, alignSelf: 'center' }}>

        <div style={{ display: 'flex', objectFit: 'contain', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${board_blank})`, width: '100%', height: 70, alignSelf: 'center', alignItems: 'center' }} >
          <div style={{ display: 'flex', flexDirection: 'row', width: '97%', alignSelf: 'center', height: 60, alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '36%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between' }}>
              <img src={url(item.Country)} alt='image' style={{ marginLeft: 15, height: 35, objectFit: 'contain' }} />
              <text style={{ color: '#FFE700', lineHeight: 35, marginLeft: 10, textAlign: 'center' }}>{item.FN}</text>
            </div>
            <div style={{ display: 'flex', width: '50%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
              <text style={{ color: '#FFE700', lineHeight: 35, textAlign: 'center', marginLeft: 4, fontSize: 13 }}>{item.W + item.D + item.L}</text>
              <text style={{ color: '#FFE700', lineHeight: 35, textAlign: 'center', fontSize: 13 }}>{item.W}</text>
              <text style={{ color: '#FFE700', lineHeight: 35, textAlign: 'center', fontSize: 13 }}>{item.D}</text>
              <text style={{ color: '#FFE700', lineHeight: 35, textAlign: 'center', fontSize: 13 }}>{item.L}</text>
              <text style={{ color: '#FFE700', lineHeight: 35, textAlign: 'center', marginRight: ranking(item.Points) == 'Marine' ? 4 : 10, fontSize: 13 }}>{ranking(item.Points)}</text>
            </div>
          </div>
        </div>

      </div >
    );
  }

  useEffect(() => {
    if (search == '') {
      let arr = rankings;
      if (arr != null) {
        setShowRanking([...arr])
      }
    }
    else {
      let temp = [];
      let rank = rankings
      if (rank != null) {
        for (let i = 0; i < rank.length; i++) {
          let fn = String(rank[i].FN).toLowerCase()
          let sn = String(rank[i].SN).toLowerCase()
          let ser = String(search).toLowerCase()
          if (fn.includes(ser) || sn.includes(ser)) {
            temp.push(rank[i])
          }
        }
        setShowRanking([...temp])
      }
    }
  }, [search])

  const openInNewTab = (url) => {
    if (url.includes('http')) window.open(url, "_blank", "noreferrer");
    else window.open("https://" + String(url).toLowerCase(), "_blank", "noreferrer");
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const [privacy, setPrivacy] = useState(false)

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [privacy]);

  const rend = (
    !isMobile ? <div style={{
      width: '100%', height: (6 * height) - 200, overflow: 'hidden', display: 'flex', flexDirection: 'column', backgroundColor: '#000000',
    }}>

      <img src={top_back} alt='image' style={{ width: width, height: height - 100, objectFit: 'fill' }} />
      <img src={back_background} alt='image' style={{ width: width, height: height - 100, objectFit: 'fill', position: 'absolute', top: 0 }} />

      <div style={{ position: 'absolute', width: '96%', alignSelf: 'center', overflow: 'hidden', alignContent: 'center', alignItems: 'center', height: '94%', top: 10, display: 'flex', flexDirection: 'column', left: '3%' }}>
        <img src={top_background} style={{ width: isMobile ? '58%' : 313, height: 70, marginTop: 0, objectFit: 'contain' }} alt="img" />
        <div style={{ width: '96%', alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

          <div style={{ width: '45%', display: 'flex', flexDirection: 'column', marginTop: 25 }}>
            <text style={{ color: 'white', fontSize: 25, fontWeight: '700', fontFamily: 'Josefin Sans' }}>NEXCHESS THE NEW</text>
            <text style={{ color: 'white', fontSize: 25, fontWeight: '700', fontFamily: 'Josefin Sans', marginTop: 6 }}>GENERATION OF CHESS</text>
            <text style={{ color: 'white', fontSize: 16, marginTop: 24, fontWeight: '500', fontFamily: 'Josefin Sans' }}>With still the same chess rules, we hope you enjoy the game as much as we built it!</text>
            <div style={{ marginRight: 6, marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: 285, justifyContent: 'space-between' }}>
              <img onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.chessapp', '_blank') }} src={play_store} style={{ cursor: 'pointer', height: 50, width: 130, objectFit: 'contain' }} />
              <img hidden src={app_store} style={{ height: 50, width: 116, objectFit: 'contain' }} />
            </div>
          </div>

          <img src={right} style={{ width: 200, height: 340, marginTop: 0, objectFit: 'contain' }} alt="img" />

        </div>
      </div>

      <img src={background} alt='image' style={{ width: width, height: height, objectFit: 'fill' }} />

      <div style={{ position: 'absolute', width: '96%', alignSelf: 'center', overflow: 'hidden', alignContent: 'center', alignItems: 'center', height: '94%', top: height - 100, display: 'flex', flexDirection: 'column', left: '3%' }}>
        <img src={heli_right} style={{ width: 350, height: 114, position: 'absolute', left: '68%', objectFit: 'contain', transform: `rotate(${10}deg)` }} alt="img" />
        <img src={jet_left} style={{ width: 470, height: 170, position: 'absolute', right: '70%', objectFit: 'contain', transform: `rotate(${220}deg)` }} alt="img" />
        <img src={army} style={{ width: 550, top: '36%', height: 550, position: 'absolute', right: '62%', objectFit: 'contain' }} alt="img" />
        <img src={tank} style={{ width: 450, top: '50%', height: 350, position: 'absolute', left: '59%', objectFit: 'contain' }} alt="img" />

        <img src={nexchess_big} style={{ width: isMobile ? '58%' : '28%', height: 70, marginTop: 0 }} alt="img" />
        <img src={leader_blank} style={{ width: isMobile ? '65%' : '37%', height: 75, marginTop: 5 }} alt="img" />
        <img src={Leader_board} style={{ width: '33%', height: 30, position: 'absolute', top: 98 }} alt="img" />
        <img onClick={() => inputref.current.focus()} src={board_blank} style={{ width: isMobile ? '90.8%' : '44.1%', height: 75, marginTop: 2 }} alt="img" />
        <div onClick={() => inputref.current.focus()} style={{ display: 'flex', position: 'absolute', top: 154, width: isMobile ? '90%' : '40%', alignSelf: 'center', height: 65, alignItems: 'center' }}>
          <input onClick={() => inputref.current.focus()} ref={inputref} value={search} type="text" placeholder='Search Your Name' onChange={(e) => setSearch(e.target.value)} style={{ marginLeft: 15, color: 'white', outline: 'none', height: 60, backgroundColor: 'transparent', borderWidth: 0, minWidth: 190 }} />
        </div>
        <img src={board_blank} style={{ width: isMobile ? '90.3%' : '44.1%', height: 72, marginTop: 2 }} alt="img" />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', top: 230, width: isMobile ? '90%' : '44%', alignSelf: 'center', height: 60, alignItems: 'center' }}>
          <div style={{ display: 'flex', width: '35%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between' }}>
            <text style={{ color: '#FFE700', lineHeight: 35, marginLeft: 16, fontSize: 13 }}>Country</text>
            <text style={{ color: '#FFE700', lineHeight: 35, marginRight: 8, fontSize: 13 }}>Name</text>
          </div>
          <div style={{ display: 'flex', width: '50%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>P</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>W</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>D</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>L</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13, marginRight: 15 }}>RANK</text>
          </div>
        </div>

        <div style={{ display: 'flex', position: 'absolute', top: 300, left: isMobile ? '3%' : '20%', overflow: "auto", height: '40%', marginTop: 1, flexDirection: 'column', width: isMobile ? '94%' : '60%', marginLeft: 7, alignSelf: 'center' }}>
          {rankings != null ? <FlatList
            list={show_ranking}
            renderItem={(id) => renderStandings(id)}
            renderWhenEmpty={() => <div style={{ height: 1, backgroundColor: '#EEEEEE' }} />}
          /> : null}
        </div>
        <img src={fire} style={{ width: '50%', height: 100, position: 'absolute', top: '87%' }} alt="img" />
      </div>

      <div style={{ width: width, top: (2 * height) + 100, height: height / 1.3, display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#FAB617' }}>
        <div style={{ height: 70, alignSelf: 'center', justifyContent: 'center', marginTop: 15 }}>
          <text style={{ color: '#893232', fontWeight: '700', textAlign: 'center', fontFamily: 'Josefin Sans' }}>History, where it all began</text>
        </div>
        <text style={{ alignSelf: 'center', width: 1020, color: '#893232', fontWeight: '500', textAlign: 'center', fontFamily: 'Josefin Sans' }}>This is the original concept video made to introduce Nexchess</text>
        <text style={{ alignSelf: 'center', width: 1000, color: '#893232', fontWeight: '500', marginBottom: 20, textAlign: 'center', fontFamily: 'Josefin Sans', marginTop: 18 }}>The success of Nexchess really does rely on the majority of chess players from around the world, especially younger players, who would like to be given the opportunity to learn how to play chess using a modern day alternative to Staunton.</text>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: 600, display: 'flex', justifyContent: 'center' }}>
          <YouTube videoId="mOrlMvLXvaE" opts={opts}
            onReady={(e) => {
              const { width, height } = e.target.getSize();
              e.target.setSize(width, width / 16 * 9)
            }}
            containerClassName={"youtubeContainer"} />
        </div>
      </div>

      <div style={{ width: width, top: (3 * height) + 20, height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#8C3631' }}>
        <div style={{ height: 50, alignSelf: 'center', justifyContent: 'center', marginTop: 10, display: 'flex', flexDirection: 'column', width: 0.90 * width }}>
          <text style={{ color: 'white', fontWeight: '700', textAlign: 'center', fontFamily: 'Josefin Sans' }}>PROJECT ROADMAP</text>
          <text style={{ width: 990, color: 'white', fontSize: 16, marginTop: 8, fontWeight: '500', fontFamily: 'Josefin Sans', textAlign: 'center' }}>Welcome to Nexchess and thank you for showing interest in my concept, its a concept that needs to be nurtured and developed, Why not enjoy the journey with us, try the app, its a free download!. Thank you.</text>

        </div>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: 1000, display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 470 }}>
          <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', width: 990, flexDirection: 'row', justifyContent: 'space-around', height: 460 }}>
            <div style={{ width: 260, height: 200, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={'https://w7.pngwing.com/pngs/422/48/png-transparent-green-tick-icon-check-icon-right-mark.png'} style={{ borderRadius: 6, height: 30, width: 30, alignSelf: 'flex-end' }} />
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 200, width: 260, objectFit: 'contain', alignSelf: 'center', marginBottom: -150 }} />
              <img src={marine_1} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 1,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}> COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>The current version of the app now available for download, a basic chess game, player to player on same device ie ipad / mobile.</text>
            </div>
            <div style={{ width: 260, height: 200, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={'https://w7.pngwing.com/pngs/422/48/png-transparent-green-tick-icon-check-icon-right-mark.png'} style={{ borderRadius: 6, height: 30, width: 30, alignSelf: 'flex-end' }} />
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 200, width: 260, objectFit: 'contain', alignSelf: 'center', marginBottom: -150 }} />

              <img src={marine_2} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 2,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 2 will incorporate an AI enabling single player against the device with multiple difficulty levels. Improvements to game pay including animations and sound effects.</text>

            </div>
            <div style={{ width: 260, height: 200, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column', }}>
              <img src={'https://w7.pngwing.com/pngs/422/48/png-transparent-green-tick-icon-check-icon-right-mark.png'} style={{ borderRadius: 6, height: 30, width: 30, alignSelf: 'flex-end' }} />
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 200, width: 260, objectFit: 'contain', alignSelf: 'center', marginBottom: -150 }} />

              <img src={marine_3} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 3,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 3 will include an online leader board where players can compare thier rankings against friends and worldwide players.</text>

            </div>
          </div>

          <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', width: 990, flexDirection: 'row', justifyContent: 'space-around', marginTop: 6 }}>
            <div style={{ width: 260, height: 200, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={marine_4} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 4,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>Where We Are Today.</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 4, App feature expansion allowing player to player across multiple devices, enabling tournaments and in game chat. sound effects</text>

            </div>
            <div style={{ width: 260, height: 200, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={marine_5} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 5,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>Where We Are Today.</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 5, App feature expansion enabling player to player across multiple devices, enabling tournaments and in game chat</text>

            </div>
            <div style={{ width: 260, height: 200, borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>

            </div>
          </div>

        </div>
      </div>


      <div style={{ width: width, top: (4 * height), height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#FFA500' }}>
        <div style={{ height: 50, alignSelf: 'center', justifyContent: 'center', marginTop: 15 }}>
        </div>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: 1000, display: 'flex', justifyContent: 'space-around', flexDirection: 'row', height: 670 }}>

          <img onClick={() => openInNewTab('https://docs.google.com/presentation/d/1a7i_usGD0R8VJOwHFtlGloE2Noi1qzal/mobilepresent?slide=id.p1')} src={require('./assets/left_view.png')} style={{ cursor: 'pointer', height: 690, width: 400, objectFit: 'contain' }} />

          <div style={{ display: 'flex', alignSelf: 'center', width: 500, flexDirection: 'column', marginLeft: 20 }}>

            <text style={{ color: '#8C3631', fontFamily: 'Josefin Sans', fontSize: 22, fontWeight: '700' }}>Investment Proposal Package.</text>

            <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 15, letterSpacing: 1.01, fontWeight: '500', marginTop: 28, textAlign: 'left' }}>I'm now 65 years old and will be receiving a pension soon. I dont work now, but i still have my project. I'm looking for outside investment to help me finish, something I thought I would never do. The potential for Nexchess as a global app is enormous; it could rival the popularity of Call of Duty or 8 Ball. There are approximately 1 billion chess players in the world, half of whom are under the age of 16, and each of these players could be playing the Nexchess.</text>
            <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 15, letterSpacing: 1.01, fontWeight: '500', marginTop: 17, textAlign: 'left' }}>As I am requesting outside investment, I dont want to put any restrictions on potential investors, but it really would help if you could play chess to appreciate Nexchess and have an agreeable understanding of my mission. I've now contacted Boom and requested a cost for finishing the project, which would include an AI player option, sound and special effects. for all missiles in the blazing version, playable against each other across multiple devices, Nexchess championships, scoring and levels, worldwide rankings and messaging. Fully loaded.

              For more information click the following link to view the complete investor proposal.</text>

          </div>

        </div>
      </div>

      <img src={require('./assets/back_background.png')} alt='image' style={{ width: width, height: height - 150, objectFit: 'fill', backgroundColor: '#FAB617' }} />

      <div style={{ position: 'absolute', width: '96%', alignSelf: 'center', overflow: 'hidden', alignContent: 'center', alignItems: 'center', height: height - 150, top: (5 * height) - 200, display: 'flex', flexDirection: 'column', left: '3%' }}>
        <div style={{ width: '96%', alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: height - 150 }}>
          <div style={{ width: '45%', display: 'flex', flexDirection: 'column', marginTop: 25 }}>
            <text style={{ color: 'white', fontSize: 25, fontWeight: '700', fontFamily: 'Josefin Sans' }}>Project Roadmap</text>
            <text style={{ color: 'white', fontSize: 16, marginTop: 24, fontWeight: '500', fontFamily: 'Josefin Sans' }}>Welcome to Nexchess and thank you for showing interest in my concept, its a concept that needs to be nurtured and developed, Why not enjoy the journey with us, try the app, its a free download!. Thank you.</text>
            <div style={{ marginRight: 6, marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: 285, justifyContent: 'space-between' }}>
              <img onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.chessapp', '_blank') }} src={play_store} style={{ cursor: 'pointer', height: 50, width: 130, objectFit: 'contain' }} />
              <img hidden src={app_store} style={{ height: 50, width: 116, objectFit: 'contain' }} />
            </div>
          </div>

          <img src={require('./assets/simulator.png')} style={{ width: 200, height: 370, marginTop: 0, objectFit: 'contain' }} alt="img" />
          <img src={require('./assets/simulator1.png')} style={{ width: 200, height: 370, marginTop: 0, objectFit: 'contain' }} alt="img" />

        </div>
      </div>

      <div style={{ backgroundColor: '#000000', width: width, alignSelf: 'center', display: 'flex', flexDirection: 'column', minHeight: 100, zIndex: 999 }}>
        <text style={{ alignSelf: 'center', width: 600, color: 'white', fontSize: 16, fontWeight: '600', fontFamily: 'Josefin Sans', textAlign: 'center', marginTop: 30 }}>Please let us know any comments you may have using the links below.</text>
        <div style={{ width: 600, display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginTop: 20 }}>
          <img src={require('./assets/facebook.png')} style={{ objectFit: 'contain', height: 25, width: 75 }} />
          <img onClick={() => window.open(`mailto:${'nexchess@gmail.com'}?subject=Subject&body=Body%20goes%20here`)} src={require('./assets/email.png')} style={{ cursor: 'pointer', objectFit: 'contain', height: 25, width: 145 }} />
          <img src={require('./assets/terms.png')} style={{ objectFit: 'contain', height: 25, width: 135 }} />
          <div onClick={() => setPrivacy(true)} style={{ cursor: 'pointer' }}>
            <text style={{ cursor: 'pointer', color: 'white', textDecorationLine: 'underline', textDecorationColor: 'white', fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: '400' }}>Privacy Policy</text>
          </div>
        </div>
      </div>


    </div> : <div style={{
      width: '100%', height: 'auto', overflow: 'hidden', display: 'flex', flexDirection: 'column', backgroundColor: '#000000',
    }}>

      <img src={top_back} alt='image' style={{ width: width, height: 1000 }} />
      <img src={back_background} alt='image' style={{ width: width, height: 1000, position: 'absolute', top: 0 }} />

      <div style={{ position: 'absolute', width: '100%', alignSelf: 'center', overflow: 'hidden', alignContent: 'center', alignItems: 'center', height: 990, top: 10, display: 'flex', flexDirection: 'column', left: '1%' }}>
        <img src={top_background} style={{ width: isMobile ? '48%' : 313, height: 70, marginTop: -15, objectFit: 'contain' }} alt="img" />
        <div style={{ width: '99%', alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: 950 }}>

          <div style={{ width: '40%', display: 'flex', flexDirection: 'column', marginTop: 15, height: 600 }}>
            <text style={{ color: 'white', fontSize: 20, fontWeight: '600', fontFamily: 'Josefin Sans' }}>NEXCHESS THE NEW</text>
            <text style={{ color: 'white', fontSize: 20, fontWeight: '600', fontFamily: 'Josefin Sans', marginTop: 6 }}>GENERATION OF CHESS</text>
            <text style={{ color: 'white', fontSize: 13, marginTop: 20, fontWeight: '500', fontFamily: 'Josefin Sans' }}>With still the same chess rules, we hope you enjoy the game as much as we built it!</text>
            <div style={{ marginRight: 6, marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: 136, alignSelf: 'flex-start', justifyContent: 'left' }}>
              <img onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.chessapp', '_blank') }} src={play_store} style={{ cursor: 'pointer', height: 50, width: 119, objectFit: 'fill', marginLeft: -7 }} />
              <img hidden src={app_store} style={{ height: 33, width: 105, objectFit: 'fill' }} />
            </div>
          </div>

          <img src={right} style={{ width: 150, height: 300, marginTop: -12, objectFit: 'contain', borderRadius: 4 }} alt="img" />


        </div>
      </div>

      <img src={background} alt='image' style={{ width: width, height: 600, objectFit: 'fill' }} />

      <div style={{ position: 'absolute', width: '96%', alignSelf: 'center', overflow: 'hidden', alignContent: 'center', alignItems: 'center', height: 700, top: 365, display: 'flex', flexDirection: 'column', left: '3%' }}>
        <img src={heli_right} style={{ width: 350, height: 114, position: 'absolute', left: '68%', objectFit: 'contain', transform: `rotate(${10}deg)` }} alt="img" />
        <img src={jet_left} style={{ width: 470, height: 170, position: 'absolute', right: '70%', objectFit: 'contain', transform: `rotate(${220}deg)` }} alt="img" />
        <img src={army} style={{ width: 450, top: 100, height: 500, position: 'absolute', right: '30%', objectFit: 'contain' }} alt="img" />
        <img src={tank} style={{ width: 400, top: 300, height: 350, position: 'absolute', left: '40%', objectFit: 'contain' }} alt="img" />

        <img src={nexchess_big} style={{ width: '58%', height: 70, marginTop: 0 }} alt="img" />
        <img src={leader_blank} style={{ width: '65%', height: 75, marginTop: 5, zIndex: 100 }} alt="img" />
        <img src={Leader_board} style={{ width: '33%', height: 30, position: 'absolute', top: 98, zIndex: 100 }} alt="img" />
        <img onClick={() => inputref.current.focus()} src={board_blank} style={{ width: isMobile ? '90.8%' : '44.1%', height: 75, marginTop: 2, zIndex: 100 }} alt="img" />
        <div onClick={() => inputref.current.focus()} style={{ display: 'flex', position: 'absolute', top: 154, zIndex: 100, width: '90%', alignSelf: 'center', height: 65, alignItems: 'center' }}>
          <input onClick={() => inputref.current.focus()} ref={inputref} value={search} type="text" placeholder='Search Your Name' onChange={(e) => setSearch(e.target.value)} style={{ marginLeft: 15, color: 'white', outline: 'none', height: 60, backgroundColor: 'transparent', borderWidth: 0, minWidth: 190 }} />
        </div>
        <img src={board_blank} style={{ width: '90.3%', height: 72, marginTop: 2, zIndex: 100, }} alt="img" />

        <div style={{ display: 'flex', zIndex: 100, flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', top: 230, width: '90%', alignSelf: 'center', height: 60, alignItems: 'center' }}>
          <div style={{ display: 'flex', width: '35%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between' }}>
            <text style={{ color: '#FFE700', lineHeight: 35, marginLeft: 16, fontSize: 13 }}>Country</text>
            <text style={{ color: '#FFE700', lineHeight: 35, marginRight: 8, fontSize: 13 }}>Name</text>
          </div>
          <div style={{ display: 'flex', width: '50%', flexDirection: 'row', height: 60, alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>P</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>W</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>D</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13 }}>L</text>
            <text style={{ color: '#FFE700', lineHeight: 35, fontSize: 13, marginRight: 15 }}>RANK</text>
          </div>
        </div>

        <div style={{ display: 'flex', position: 'absolute', top: 300, left: '2%', overflow: "auto", height: 350, marginTop: 1, flexDirection: 'column', width: '94%', marginLeft: 7, alignSelf: 'center' }}>
          {rankings != null ? <FlatList
            list={show_ranking}
            renderItem={(id) => renderStandings(id)}
            renderWhenEmpty={() => <div style={{ height: 1, backgroundColor: '#EEEEEE' }} />}
          /> : null}
        </div>
        <img src={fire} style={{ width: '50%', height: 100, position: 'absolute', top: 580 }} alt="img" />
      </div>

      <div style={{ position: 'absolute', width: width, top: 1066, height: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#FAB617' }}>
        <div style={{ height: 40, alignSelf: 'center', justifyContent: 'center', marginTop: 15 }}>
          <text style={{ color: '#893232', fontWeight: '700', textAlign: 'center', fontFamily: 'Josefin Sans' }}>History, where it all began</text>
        </div>
        <text style={{ alignSelf: 'center', width: 400, color: '#893232', fontWeight: '500', textAlign: 'center', fontFamily: 'Josefin Sans' }}>This is the original concept video made to introduce Nexchess</text>
        <text style={{ alignSelf: 'center', width: 300, color: '#893232', fontWeight: '500', marginBottom: 20, textAlign: 'center', fontFamily: 'Josefin Sans', marginTop: 8 }}>The success of Nexchess really does rely on the majority of chess players from around the world, especially younger players, who would like to be given the opportunity to learn how to play chess using a modern day alternative to Staunton.</text>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: 200, display: 'flex', justifyContent: 'center' }}>
          <YouTube videoId="mOrlMvLXvaE" opts={opts}
            onReady={(e) => {
              const { width, height } = e.target.getSize();
              e.target.setSize(width, width / 16 * 9)
            }}
            containerClassName={"youtubeContainer"} />
        </div>
      </div>

      <div style={{ width: width, position: 'absolute', top: 1567, height: 720, display: 'flex', flexDirection: 'column', backgroundColor: '#8C3631' }}>
        <div style={{ height: 18, alignSelf: 'center', justifyContent: 'center', marginTop: 2 }}>
          <text style={{ color: 'white', fontWeight: '700', textAlign: 'center', fontFamily: 'Josefin Sans' }}>PROJECT ROADMAP</text>
        </div>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: width, display: 'flex', flexDirection: 'column', height: 670 }}>

          <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', width: 350, flexDirection: 'row', justifyContent: 'space-around', height: 385 }}>
            <div style={{ width: 150, height: 185, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 185, width: 150, objectFit: 'contain', alignSelf: 'center', marginBottom: -90 }} />

              <img src={marine_1} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 1,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>The current version of the app now available for download, a basic chess game, player to player on same device ie ipad / mobile.</text>
            </div>
            <div style={{ width: 150, height: 185, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 185, width: 150, objectFit: 'contain', alignSelf: 'center', marginBottom: -90 }} />

              <img src={marine_2} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 2,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 2 will incorporate an AI enabling single player against the device with multiple difficulty levels. Improvements to game pay including animations and sound effects.</text>

            </div>
          </div>

          <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', width: 350, flexDirection: 'row', justifyContent: 'space-around', marginTop: 3, height: 385 }}>
            <div style={{ width: 150, height: 185, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <img src={require('./assets/mission.png')} style={{ zIndex: 100, height: 185, width: 150, objectFit: 'contain', alignSelf: 'center', marginBottom: -90 }} />

              <img src={marine_3} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 3,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>COMPLETE</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 3 will include an online leader board where players can compare thier rankings against friends and worldwide players.</text>

            </div>
            <div style={{ width: 150, height: 185, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={marine_4} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 4,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>Where We Are Today.</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 4, App feature expansion allowing player to player across multiple devices, enabling tournaments and in game chat. sound effects</text>

            </div>
          </div>

          <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', width: 350, flexDirection: 'row', marginTop: 3, justifyContent: 'space-around', height: 385 }}>
            <div style={{ width: 150, height: 185, borderStyle: 'solid', borderColor: '#FFBD00', borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>
              <img src={marine_5} style={{ height: 70, width: 70, objectFit: 'contain', alignSelf: 'center' }} />
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 6, textTransform: 'capitalize' }}>App Phase 5,</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 10, fontWeight: '500', textAlign: 'center', marginTop: 2, textTransform: 'capitalize' }}>Where We Are Today.</text>
              <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 11, letterSpacing: 1.01, fontWeight: '500', marginTop: 8, textAlign: 'center' }}>Phase 5, App feature expansion enabling player to player across multiple devices, enabling tournaments and in game chat</text>

            </div>
            <div style={{ width: 150, height: 185, borderRadius: 4, padding: 6, display: 'flex', flexDirection: 'column' }}>

            </div>
          </div>

        </div>
      </div>

      <div style={{ width: width, position: 'absolute', top: 2287, height: 900, display: 'flex', flexDirection: 'column', backgroundColor: '#FFA500' }}>
        <div style={{ height: 50, alignSelf: 'center', justifyContent: 'center', marginTop: 15 }}>
        </div>
        <div style={{ alignSelf: 'center', marginBottom: 3, width: 400, display: 'flex', justifyContent: 'space-around', flexDirection: 'column', height: 1070, alignItems: 'center' }}>


          <div style={{ display: 'flex', alignSelf: 'center', width: 350, flexDirection: 'column', marginLeft: 10, marginTop: 10 }}>

            <text style={{ color: '#8C3631', fontFamily: 'Josefin Sans', fontSize: 20, fontWeight: '600' }}>Investment Proposal Package.</text>

            <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 14, letterSpacing: 1.01, fontWeight: '500', marginTop: 25, textAlign: 'left' }}>I'm now 65 years old and will be receiving a pension soon. I dont work now, but i still have my project. I'm looking for outside investment to help me finish, something I thought I would never do. The potential for Nexchess as a global app is enormous; it could rival the popularity of Call of Duty or 8 Ball. There are approximately 1 billion chess players in the world, half of whom are under the age of 16, and each of these players could be playing the Nexchess.</text>
            <text style={{ color: 'white', fontFamily: 'Josefin Sans', fontSize: 14, letterSpacing: 1.01, fontWeight: '500', marginTop: 14, textAlign: 'left' }}>As I am requesting outside investment, I dont want to put any restrictions on potential investors, but it really would help if you could play chess to appreciate Nexchess and have an agreeable understanding of my mission. I've now contacted Boom and requested a cost for finishing the project, which would include an AI player option, sound and special effects. for all missiles in the blazing version, playable against each other across multiple devices, Nexchess championships, scoring and levels, worldwide rankings and messaging. Fully loaded.

              For more information click the following link to view the complete investor proposal.</text>

          </div>

          <img src={require('./assets/left_view.png')} style={{ height: 350, width: 300, objectFit: 'fill' }} />

        </div>
      </div>

      <img src={require('./assets/back_background.png')} alt='image' style={{ width: width, height: 400, objectFit: 'fill', position: 'absolute', top: 3187 }} />

      <div style={{ position: 'absolute', width: '96%', alignSelf: 'center', overflow: 'hidden', zIndex: 100, alignContent: 'center', alignItems: 'center', height: 400, top: 3177, display: 'flex', flexDirection: 'column', left: '1%' }}>
        <div style={{ width: '96%', alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'column', height: 400 }}>
          <div style={{ width: '85%', display: 'flex', flexDirection: 'column', marginTop: 15, height: 200 }}>
            <text style={{ color: 'white', fontSize: 20, fontWeight: '700', fontFamily: 'Josefin Sans' }}>Project Roadmap</text>
            <text style={{ color: 'white', fontSize: 12, marginTop: 24, fontWeight: '500', fontFamily: 'Josefin Sans' }}>Welcome to Nexchess and thank you for showing interest in my concept, its a concept that needs to be nurtured and developed, Why not enjoy the journey with us, try the app, its a free download!. Thank you.</text>
            <div style={{ marginRight: 6, marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: 285, justifyContent: 'space-between' }}>
              <img onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.chessapp', '_blank') }} src={play_store} style={{ cursor: 'pointer', height: 50, width: 130, objectFit: 'contain' }} />
              <img hidden src={app_store} style={{ height: 50, width: 116, objectFit: 'contain' }} />
            </div>
          </div>
          <div style={{ width: '85%', flexDirection: 'row', marginTop: -30, display: 'flex', marginLeft: -10 }}>
            <img src={require('./assets/simulator.png')} style={{ width: 200, height: 200, marginTop: 0, objectFit: 'contain' }} alt="img" />
            <img src={require('./assets/simulator1.png')} style={{ width: 200, height: 200, marginTop: 0, objectFit: 'contain' }} alt="img" />
          </div>

        </div>
      </div>

      <div style={{ backgroundColor: '#000000', width: width, height: 250, alignSelf: 'center', display: 'flex', flexDirection: 'column', minHeight: 200, zIndex: 999, marginTop: 2150 }}>
        <text style={{ alignSelf: 'center', width: 400, color: 'white', fontSize: 16, fontWeight: '600', fontFamily: 'Josefin Sans', textAlign: 'center', marginTop: 30 }}>Please let us know any comments you may have using the links below.</text>
        <div style={{ width: 330, display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginTop: 20 }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={require('./assets/facebook.png')} style={{ objectFit: 'contain', height: 25, width: 75 }} />
            <img onClick={() => window.open(`mailto:${'nexchess@gmail.com'}?subject=Subject&body=Body%20goes%20here`)} src={require('./assets/email.png')} style={{ cursor: 'pointer', objectFit: 'contain', height: 25, width: 145 }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
            <img src={require('./assets/terms.png')} style={{ objectFit: 'contain', height: 25, width: 135 }} />
            <div onClick={() => setPrivacy(true)} style={{ cursor: 'pointer' }}>
              <text style={{ cursor: 'pointer', color: 'white', textDecorationLine: 'underline', textDecorationColor: 'white', fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: '400' }}>Privacy Policy</text>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

  return (privacy ? <div style={{ width: width, height: 'auto', display: 'flex', flexDirection: 'column' }}>
    <img src={require('./assets/header.jpg')} style={{ height: 110, width: 0.90 * width, alignSelf: 'center' }} />
    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'center', color: 'black', fontWeight: '600' }}>Privacy Policy</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>At Nexchess, accessible from <text onClick={() => openInNewTab('http://www.nexchess.com')} style={{ color: 'green', cursor: 'pointer' }}> http://www.nexchess.com </text>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Nexchess how we use it.</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at<text onClick={() => window.open(`mailto:${'nexchess@gmail.com'}?subject=Subject&body=Body%20goes%20here`)} style={{ color: 'green', cursor: 'pointer' }}> nexchess@gmail.com </text></text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>This Privacy Policy applies to our online activities through the Nexchess website and to any email communications Nexchess receives from outside of our website. This policy is not applicable to any information collected offline or via channels other than this website and email communications.</text>
    </div>
    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>Consent</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</text>
    </div>
    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>Information we collect or may receive</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>We may collect personal information from you when you submit web forms on our Website, or when you use interactive features of the website including but not limited to communicating with us via email, or communicating with us through other channels.</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</text>
    </div>
    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>Disclosure of Personal Information</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>We will not disclose your information to any third party except if you expressly consent or where required by law.
      </text>
    </div>

    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>How we use your information</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>We use the information we collect in various ways, including to:</text>

      <text style={{ marginLeft: 10, fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 7, fontWeight: '500', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <div style={{ backgroundColor: 'black', height: 8, width: 8, borderRadius: 4, marginRight: 6 }} />Provide, operate, and maintain our website</text>
      <text style={{ marginLeft: 10, fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 7, fontWeight: '500', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <div style={{ backgroundColor: 'black', height: 8, width: 8, borderRadius: 4, marginRight: 6 }} />Improve, personalize, and expand our website</text>
      <text style={{ marginLeft: 10, fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 7, fontWeight: '500', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <div style={{ backgroundColor: 'black', height: 8, width: 8, borderRadius: 4, marginRight: 6 }} />Develop new products, services, features, and functionality</text>
      <text style={{ marginLeft: 10, fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 7, fontWeight: '500', display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <div style={{ backgroundColor: 'black', height: 8, width: 8, borderRadius: 4, marginRight: 6 }} />Communicate with you in response to feedback provided by you in email or other channels.</text>

      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>If you email the Nexchess for support or other feedback, the emails with email addresses will be retained for quality assurance purposes. The email addresses will be used only to reply to the concerns or suggestions raised and will never be used for any marketing purpose.</text>
    </div>

    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>Log Files</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>Nexchess website does not create, store or maintain log files of any kind relating website visitors or other website usage data.
      </text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
      </text>
    </div>

    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
      <text style={{ fontFamily: 'Poppins', fontSize: 27, textAlign: 'left', color: 'black', fontWeight: '600' }}>Children's Information</text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
      </text>
      <text style={{ fontFamily: 'Poppins', fontSize: 16, textAlign: 'left', color: 'black', marginTop: 15, fontWeight: '500' }}>Nexchess does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will promptly remove such information from our records.
      </text>
    </div>

    <div style={{ marginTop: 30, width: 0.90 * width, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: 30 }}>
      <div onClick={() => setPrivacy(false)} style={{ width: 175, height: 35, borderRadius: 4, display: 'flex', justifyContent: 'center', borderStyle: 'solid', borderColor: 'black', borderWidth: 0.5, backgroundColor: '#bc6314', alignItems: 'center', alignContent: 'center' }}>
        <text style={{ fontWeight: '500', fontFamily: 'Poppins', color: 'white', fontSize: 13, textAlign: 'center' }}>Back To Main Page</text>
      </div>
    </div>

  </div> : rend
  );
}

export default App;

/*

FN : First Name ,
SN : Sur Name ,
W : win,
D : Draw ,
L : Lose ,
Email : mail ,
Password:pass,
Country:code,
Points:nm,

*/